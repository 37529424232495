<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow">
      <div class="column q-col-gutter-sm">
        <q-expansion-item
          class="bg-grey-4"
          expand-separator
          :label="data.visitDetail.nama_customer"
          :caption="moment(data.visitDetail.date).format('DD-MMM-YYYY')"
        >
          <q-card class="bg-grey-5">
            <q-card-section>
              <q-markup-table flat class="bg-grey-5">
                <tr>
                  <td>Costumer Type</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.customer_type }}</td>
                </tr>
                <tr>
                  <td>Application</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.application }}</td>
                </tr>
                <tr v-if="curUser.is_sales == 1">
                  <td>Application</td>
                  <td>:</td>
                  <td>{{ data.visitDetail.type }}</td>
                </tr>
                <tr v-if="curUser.is_admin == 1">
                  <td>Visit Type</td>
                  <td>:</td>
                  <td>
                    <q-select
                      outlined
                      dense
                      class="bg-white"
                      v-model="data.visitDetail.type"
                      :options="data.visitType"
                    ></q-select>
                  </td>
                </tr>
              </q-markup-table>
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <div class="column q-gutter-sm q-pa-md">
          <q-btn
            unelevated
            stack
            icon="upload"
            color="primary"
            label="upload photo"
            v-if="
              data.visitDetail.is_check == 0 && data.visitDetail.photo == ''
            "
            @click="dialogMethod = true"
          ></q-btn>
          <q-btn
            outline
            color="secondary"
            stack
            icon="image"
            label="Lihat Foto"
            @click="showFoto = true"
            v-if="data.visitDetail.photo && data.visitDetail.photo != ''"
          ></q-btn>
          <q-btn
            outline
            color="primary"
            label="check-in ulang"
            @click="checkIn(route.params.id)"
            :disable="
              data.visitDetail.is_summary_unlocked == '0' &&
                moment().isAfter(moment(data.visitDetail.date), 'days')
            "
          ></q-btn>
          <!-- <q-btn
            outline
            color="primary"
            label="check-in"
            v-if="
              data.visitDetail.is_check == 0 &&
                moment(data.visitDetail.date).format('DDMMYY') ==
                  moment().format('DDMMYY')
            "
            @click="checkIn(route.params.id)"
          ></q-btn> -->
          <q-input
            outlined
            stack-label
            type="textarea"
            label="visit summary"
            :readonly="
              data.visitDetail.is_summary_unlocked == '0' &&
                moment().isAfter(moment(data.visitDetail.date), 'days')
            "
            v-model="data.visitDetail.summary"
          ></q-input>
        </div>
        <div
          class="column q-gutter-sm q-pa-md"
          v-if="data.visitDetail.is_check == 1"
        >
          <div class="column q-gutter-xs">
            <label>Status Validasi : </label>
            <q-select
              outlined
              dense
              v-model="data.visitDetail.is_valid"
              :options="optValid"
              map-options
              emit-value
              :readonly="curUser.is_admin == 0"
            ></q-select>
          </div>
          <div class="column q-gutter-xs">
            <label>Catatan Invalid : </label>
            <q-input
              outlined
              type="textarea"
              v-model="data.visitDetail.invalid_note"
              :readonly="curUser.is_admin == 0"
            ></q-input>
          </div>
        </div>
      </div>
    </q-scroll-area>
    <q-btn
      class="no-border-radius"
      color="primary"
      label="save"
      :disable="
        curUser.is_admin == 0 &&
          data.visitDetail.is_summary_unlocked == '0' &&
          moment().isAfter(moment(data.visitDetail.date), 'days')
      "
      v-if="data.visitDetail.is_check == 0 || curUser.is_admin == 1"
      @click="saveSummary()"
    ></q-btn>
    <q-dialog v-model="showFoto">
      <q-card style="min-width:300px">
        <q-card-section>
          <q-img
            :src="`../bukti_visit/${data.visitDetail.photo}`"
            spinner-color="white"
          ></q-img>
        </q-card-section>
        <q-card-actions align="between">
          <q-btn
            outline
            v-if="data.visitDetail.is_check == 0"
            color="primary"
            label="Ambil Ulang Gambar"
            @click="dialogMethod = true"
            v-close-popup
          ></q-btn>
          <q-btn unelevated color="primary" label="Close" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogMethod">
      <q-card style="width:300px">
        <q-card-section class="q-gutter-y-sm">
          <q-btn
            outline
            class="full-width"
            color="primary"
            icon="image"
            label="Pilih Dari Galeri"
            @click="dialogUpload = true"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            class="full-width"
            color="primary"
            icon="photo_camera"
            label="Gunakan Kamera"
            :to="`/visit/camera/${route.params.id}`"
            v-close-popup
          ></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogUpload" persistent>
      <q-card style="width:425px">
        <q-card-section class="row items-center justify-between">
          <span class="text-h6">Pilih Gambar</span>
          <q-btn
            flat
            icon="close"
            @click="data.img = null"
            v-close-popup
          ></q-btn>
        </q-card-section>
        <q-card-section v-if="data.img">
          <q-img
            class="col-8"
            :src="capture"
            :ratio="1"
            style="max-height:650%"
          ></q-img>
        </q-card-section>
        <q-card-section class="q-gutter-y-sm">
          <q-file
            outlined
            v-model="data.img"
            @update:model-value="getImg"
            accept="image/*"
          >
            <template v-slot:prepend>
              <q-icon name="attach_file" />
            </template>
          </q-file>
          <q-btn
            class="full-width"
            v-if="data.img"
            label="Upload Gambar Ini"
            color="primary"
            @click="savepict(route.params.id)"
            v-close-popup
          ></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import useVisit from "./useVisit";
export default {
  setup() {
    const {
      getDetail,
      data,
      checkIn,
      route,
      writeSummary,
      curUser,
      saveSummaryByAdmin,
      getType,
      $q,
      savepict,
    } = useVisit();

    let showFoto = ref(false);

    let dialogMethod = ref(false);
    let dialogUpload = ref(false);

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getType();
        await getDetail(route.params.id);
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let saveSummary = async () => {
      if (curUser.value.is_sales == 1) await writeSummary(data.visitDetail);
      else await saveSummaryByAdmin(data.visitDetail);
    };

    let capture = ref(null);
    let getImg = () => {
      capture.value = URL.createObjectURL(data.img);
    };

    return {
      getDetail,
      data,
      checkIn,
      route,
      writeSummary,
      moment,
      showFoto,
      curUser,
      saveSummary,
      optValid: ref([
        { label: "Valid", value: "1" },
        { label: "Invalid", value: "0" },
      ]),
      dialogMethod,
      dialogUpload,
      getImg,
      capture,
      savepict,
    };
  },
};
</script>
